import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'index',
        component: () => import('./components/index/index'),
        children: [
            {
                path: '/',
                name: 'newInformation',
                component: () => import('./components/index/newInformation')
            },
            {
                path: '/test',
                name: 'test',
                component: () => import('./components/index/test')
            }
        ]

    },
    {
        path: '/publicArtical',
        component: () => import('./components/public/publicArtical'),
        children: [
            {
                path: '/newsDetails',
                component: () => import('./components/public/newsDetails')
            },
        ]
    },
    {
        path: '/industryInformation',
        name: 'industryInformation',
        component: () => import('./components/policy/industryInformation'),
        children: [
            {
                path: '/dynamic',
                name: 'dynamic',
                component: () => import('./components/policy/dynamic')
            },
            {
                path: '/member',
                name: 'member',
                component: () => import('./components/policy/member')
            },
            {
                path: '/dynamicDetails',
                name: 'dynamicDetails',
                component: () => import('./components/policy/dynamicDetails'),
            }
        ]
    },
    {
        path: '/policy',
        name: 'policy',
        component: () => import('./components/policy/policy'),
        children: [
            {
                path: '/NewDeal',
                name: 'newDeal',
                component: () => import('./components/policy/NewDeal'),
            },
            {
                path: '/place',
                name: 'newDeal',
                component: () => import('./components/policy/place'),
            },
            {
                path: '/industryDetails',
                name: 'industryDetails',
                component: () => import('./components/policy/Details'),
            }
        ]
    },
    {
        path: '/expert',
        name: 'expert',
        component: () => import('./components/expert/expert'),
        children: [
            {
                path: '/mien',
                name: 'mien',
                component: () => import('./components/expert/mien'),
            },
            {
                path: '/expertDetails',
                name: 'expertDetails',
                component: () => import('./components/expert/expertDetails'),
            },
            {
                path: '/literature',
                name: 'literature',
                component: () => import('./components/expert/literature'),
            }, {
                path: '/literatureDetails',
                name: 'literatureDetails',
                component: () => import('./components/expert/literatureDetails'),
            },
            {
                path: '/report',
                name: 'report',
                component: () => import('./components/expert/report'),
            },
        ]
    },
    {
        path: '/associationServices',
        name: 'associationServices',
        component: () => import('./components/associationServices/associationServices'),
        children: [
            {
                path: '/important',
                name: 'important',
                component: () => import('./components/associationServices/important'),
            },
            {
                path: '/training',
                name: 'training',
                component: () => import('./components/associationServices/training'),
            }, {
                path: '/trainingDetails',
                name: '/trainingDetails',
                component: () => import('./components/associationServices/trainingDetails'),
            },
        ]
    },
    {
        /*会企*/
        path: '/memberAssociation',
        name: 'memberAssociation',
        component: () => import('./components/memberAssociation/memberAssociation'),
        children: [
            {
                path: '/synopsis',
                name: 'synopsis',
                component: () => import('./components/memberAssociation/synopsis'),
            },
            {
                path: '/businessDetails',
                name: 'businessDetails',
                component: () => import('./components/memberAssociation/businessDetails'),
            },
            {
                path: '/supply',
                name: 'supply',
                component: () => import('./components/memberAssociation/supply'),
            },
            {
                path: '/supplyDetails',
                name: 'supplyDetails',
                component: () => import('./components/memberAssociation/supplyDetails'),
            }

        ]
    },
    // 协会概况
    {
        path: '/association',
        name: 'association',
        component: () => import('./components/association/association'),
        children: [
            {
                path: '/constitution',
                query: {name: '协会章程'},
                component: () => import('./components/association/constitution'),
            },
            {
                path: '/applicationMembership',
                name: 'applicationMembership',
                component: () => import('./components/association/applicationMembership')
            }, {
                path: '/organizationalStructure',
                name: 'organizationalStructure',
                component: () => import('./components/association/organizationalStructure')
            }, {
                path: '/introductionAssociation',
                name: 'applicationMembership',
                component: () => import('./components/association/introductionAssociation')
            },
            {
                path: '/deeds',
                name: 'deeds',
                component: () => import('./components/association/deeds')
            },
            {
                path: '/institution',
                name: 'institution',
                component: () => import('./components/association/institution')
            }
        ]
    },
    {
        // 联系我们
        path: '/connection',
        name: 'connection',
        component: () => import('./components/connection/connection'),

    },
    {

        path: '/self-discipline',
        name: 'self-discipline',
        component: () => import('./components/self-discipline/self-discipline'),
        children: [
            {
                path: '/disciplineContent',
                name: 'disciplineContent',
                component: () => import('./components/self-discipline/disciplineContent')
            }
        ]

    },
    {
        path: '/companiesEnroll',
        name: 'enroll',
        component:()=>import('./components/companies/enroll')
    }
]
const router = new VueRouter({
    routes
})

export default router