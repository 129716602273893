<template>
    <router-view></router-view>
</template>
<script>

export default {
  name: 'App'
}
</script>

<style>
body{
  cursor: default;
}
.ql-bubble{
  cursor: default;
}

</style>
