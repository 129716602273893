import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from "axios";
import constContent from './ConstContent'

Vue.config.productionTip = false

Vue.prototype.$http=axios

Vue.prototype.$constContent=constContent

axios.defaults.baseURL = 'https://www.xxatis.com/service/'
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
